import styled from '@emotion/styled';
import { Fragment } from 'react';
import { Constants, Field_Keys, Styles, Utils } from '../../../constants';
import { isArray } from '../../../lib/js';
import {
  DateTypes,
  formatDate,
  howLongAgo,
  humanFormalDate,
  humanReadableDate,
} from '../../../utils/timeUtils';
import { parseStringModifiers } from '../../../utils/utils';
import { Icon, Text } from '../../atoms';
import { Flex } from '../../atoms/Flex/Flex';
import { Icons } from '../../atoms/Icon/Icon.options';
import { Separator } from '../../atoms/Separator/Separator';
import { CheckBox } from '../../molecules';
import Avatar from '../../molecules/Avatar/Avatar';
import { Badge, BadgeType } from '../../molecules/Badge/Badge';
import Button from '../../molecules/Button/Button';
import ContractedBrandName from '../../molecules/ContractedBrandName/ContractedBrandName';
import CopyableText from '../../molecules/CopyableText/CopyableTitle';
import DynamicBadge from '../../molecules/DynamicBadge/DynamicBadge';
import FloatingMenu from '../../molecules/FloatingMenu/FloatingMenu';
import { ItemPreviewImage } from '../../molecules/ItemPreviewImage/ItemPreviewImage';
import { Label } from '../../molecules/Label/Label';
import { StatusShares } from '../../molecules/StatusShares/StatusShares';
import Tooltip from '../../molecules/Tooltip/Tooltip';
import { getTooltipKey } from '../../molecules/Tooltip/utils';
import {
  Colors,
  Effects,
  FontKeys,
  Layout,
  Size,
  StatusColors,
} from '../../style';
import {
  HeaderRow,
  ProductQuantityBox,
  SeparatorCSS,
  TableRow,
  Td,
  Th,
  separatorColor,
  transition,
} from './Table.style';

// TABLE COMPONENTS - START
//
//
export const TableHeader = ({ headerRowRef, headers, grid }) => {
  return (
    <HeaderRow grid={grid} ref={headerRowRef} className="header-row">
      {Object.values(headers).map((header, index) => (
        <Th key={header + index} className={`table-header _${index}`}>
          <TableHeaderText text={header} />
        </Th>
      ))}
    </HeaderRow>
  );
};

export const TableHeaderText = ({ text, color, ...props }) => {
  return (
    <Text
      text={text}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size._S}
      weight={FontKeys.Weight.Semibold}
      color={color ?? Colors.Neutral._500}
      ellipsis
      {...props}
    />
  );
};

export const TableRows = ({
  data,
  selectedRow,
  grid,
  rowIsSelected,
  scrollHideUp,
  scrollHideDown,
  headers,
  components,
  actionRowClick,
  handleRowHoverIn,
  handleRowHoverOut,
  rowIsClickable,
  selectedRows,
  firstRowRef,
}) => {
  return data.map((item, rowIndex) => {
    const selected = selectedRow === rowIndex;
    const isLastRow = rowIndex === data.length - 1;
    return (
      // <LazyLoadComponent>
      <TableRow
        key={'row' + rowIndex}
        grid={grid}
        className={`table-row _${rowIndex}`}
        rowIsSelected={rowIsSelected}
        selected={selected}
        beforeSelected={rowIndex < selectedRow}
        afterSelected={rowIndex > selectedRow}
        scrollHideUp={scrollHideUp}
        scrollHideDown={scrollHideDown}
        isClickable={rowIsClickable}
        onPointerOver={handleRowHoverIn(item, rowIndex)}
        onPointerOut={handleRowHoverOut(item, rowIndex)}
        ref={rowIndex === 0 ? firstRowRef : null}
      >
        {Object.keys(headers).map((key, columnIndex) => {
          const { display, action } = components[key] ?? {};
          const rowSelected = selectedRows?.includes(rowIndex);
          if (display)
            return (
              <Td
                onClick={actionRowClick(action, item, rowIndex)}
                key={`${key}-${rowIndex}-${columnIndex}`}
                isClickable={rowIsClickable || !!action}
                className={`table-cell _${columnIndex}`}
                rowSelected={rowSelected}
              >
                {display(item, rowIndex, {
                  expanded: selected,
                })}
              </Td>
            );
          return (
            <Fragment key={`${key}-${rowIndex}-${columnIndex}-fragment`} />
          );
        })}
        {
          <SeparatorCSS key={rowIndex + 'separator'}>
            <Separator
              background={isLastRow ? 'transaparent' : separatorColor}
            />
          </SeparatorCSS>
        }
      </TableRow>
      // </LazyLoadComponent>
    );
  });
};
//
//
// TABLE COMPONENTS - END

// ROW ITEMS - START
//
//
export const Space = _ => ' ';

export const runIfHasProducts =
  ({ action, countKey }) =>
  (item, ...args) => {
    if (item[countKey].length === 0) return Utils.avoidRowExpand;
    return action(item, ...args);
  };

export const ExpansionArrow =
  ({ countKey }) =>
  (item, __, { expanded } = {}) => {
    const { [countKey]: expandItems } = item;
    const isAllowed = expandItems?.length > 0;

    return (
      <ExpansionArrowCSS expanded={isAllowed && expanded}>
        <Icon
          name={Icons.ChevronRight}
          color={isAllowed ? undefined : Colors.Neutral._400}
        />
      </ExpansionArrowCSS>
    );
  };

const ExpansionArrowCSS = styled.div`
  svg {
    transform: rotate(${({ expanded }) => (expanded ? 90 : 0)}deg);
    transition: ${transition};
  }
`;

export const RowSingleImage =
  (photosKey, { showGallery, disabled } = {}) =>
  size =>
  item => {
    const { [photosKey]: photos } = item;
    if (photos == null)
      return (
        <div className="pulse">
          <ItemPreviewImage size={size} />
        </div>
      );

    const src = disabled ? '' : photos?.[0]?.[Field_Keys.PHOTO.THUMBNAIL] ?? '';

    const onClick =
      !disabled && showGallery
        ? showGallery(
            {
              item,
            },
            photos
          )
        : null;

    return <ItemPreviewImage src={src} size={size} onClick={onClick} />;
  };

export const RowMultipleImages =
  ({ photosKey, showGallery, amount = 6, size = '_S', hoverZoom }) =>
  item => {
    const { [photosKey]: photos } = item;
    if (photos == null)
      return (
        <Flex gap="_2XS">
          {[...Array(amount)].map((e, index) => (
            <div className="pulse" key={index}>
              <ItemPreviewImage key={index} size={size} />
            </div>
          ))}
        </Flex>
      );

    return (
      <Flex gap="_2XS">
        {(photos ?? [...Array(1)]).slice(0, amount).map((photo, index) => {
          // TODO: mask thumbnail everywhere so it's always just strings
          const value = photo?.[Field_Keys.PHOTO.THUMBNAIL] ?? photo ?? '';
          return (
            <ItemPreviewImage
              src={value}
              key={index}
              onClick={
                showGallery &&
                showGallery(
                  {
                    item,
                    initialSelected: index,
                  },
                  photos
                )
              }
              size={size}
              amount={amount}
              index={index}
              noOfImages={photos.length}
              hoverZoom={hoverZoom}
            />
          );
        })}
      </Flex>
    );
  };

const RowDate =
  column =>
  (
    key,
    { time: time_ = true, boldDate = true, defaultToString = false } = {}
  ) =>
  item => {
    const { [key]: date } = item;

    const fallback = defaultToString ? date : Constants.fallback;

    const calendarDate = formatDate(date, DateTypes.ddmmyy) ?? fallback;

    const time = time_ ? formatDate(date, DateTypes.time12) : '';

    if (!column) {
      const content = time_
        ? `**${calendarDate} ${time ? Constants.fallback : ''}** ${time}`
        : boldDate
        ? `**${calendarDate}**`
        : calendarDate;
      return <RowText text={parseStringModifiers(content)} />;
    }

    return (
      <Flex column={column} gap={column ? '' : '_3XS'} w0 grow>
        {time_ ? (
          <RowBoldText text={calendarDate} ellipsis={column} />
        ) : (
          <RowText text={calendarDate} />
        )}
        <RowText text={time} />
      </Flex>
    );
  };

export const RowDateHorizontal = RowDate(false);
export const RowDateVertical = RowDate(true);

export const RowHumanReadableDate = key => item => {
  const { [key]: date } = item;

  const text = humanReadableDate(date) ?? Constants.fallback;

  return <RowText text={text} />;
};

export const RowFormalDate = key => item => {
  const { [key]: date } = item;

  const text = humanFormalDate(date) ?? Constants.fallback;

  return <RowText text={text} />;
};

export const RowHowLongAgo = key => item => {
  const { [key]: date } = item;

  const text = howLongAgo(date) ?? Constants.fallback;

  return <RowLightText text={text} />;
};

export const JoinedStrings =
  (
    firstKey,
    secondKey,
    separator = Constants.fallback,
    { disabled, column = false } = {}
  ) =>
  item => {
    const { [firstKey]: first, [secondKey]: second } = item;

    const hasFirst = first != null && first !== '';
    const hasSecond = second != null && second !== '';

    const hasBoth = hasFirst && hasSecond;

    let text = hasFirst ? `**${first}**` : '';
    text += hasBoth ? (column ? '\n' : ` **${separator}** `) : '';
    text += hasSecond ? second : '';

    text = parseStringModifiers(text);

    return (
      <Flex gap={'_3XS'} w0 grow column={column}>
        <RowText text={text} disabled={disabled} ellipsis />
      </Flex>
    );
  };

export const RowLabel =
  (
    key,
    {
      iconLeft,
      iconRight,
      color = Colors.Primary._600,
      background,
      onClick,
      hasBorder,
      borderColor,
      size = '_M',
      noPadding = true,
    } = {}
  ) =>
  item => {
    const {
      [key]: label,
      [iconLeft]: itemIconLeft,
      [iconRight]: itemIconRight,
    } = item ?? {};
    borderColor = borderColor ?? color;

    const displayIconLeft =
      Icons[itemIconLeft] ?? Icons[iconLeft] ?? itemIconLeft;
    const displayIconRight =
      Icons[itemIconRight] ?? Icons[iconRight] ?? itemIconRight;

    return (
      <Label
        text={label}
        textColor={color}
        hasBorder={hasBorder}
        borderColor={borderColor}
        onClick={onClick}
        background={background}
        size={size}
        leftIcon={displayIconLeft}
        rightIcon={displayIconRight}
        noPadding={noPadding}
      />
    );
  };

export const RowDynamicBadge = key => item => {
  const { [key]: props } = item;

  return <DynamicBadge {...props} />;
};

// make this a more generic component - bold dash light or something
const Location = column => (sorterKey, cityKey) => item => {
  const { [sorterKey]: sorter, [cityKey]: city } = item;

  return (
    <Flex column={column} gap={column ? '' : '_3XS'} w0 grow>
      <RowBoldText text={sorter} ellipsis={false} />
      {!column && <RowBoldText text={`  ${Constants.fallback}  `} />}
      <RowText text={city} />
    </Flex>
  );
};

export const LocationHorizontal = Location(false);

const BaseText = ({
  text,
  weight,
  color,
  blue,
  disabled,
  ellipsis = true,
  size = '_M',
  onClick,
  customColor,
}) => {
  if (blue) color = Colors.Primary._600;
  if (disabled) color = Colors.Neutral._500;
  if (customColor) color = customColor;
  return (
    <Text
      text={text ?? Constants.fallback}
      variant={FontKeys.Variant.Paragraph}
      size={FontKeys.Size[size]}
      weight={weight ?? FontKeys.Weight.Regular}
      color={color}
      ellipsis={ellipsis}
      whiteSpace="nowrap"
      onClick={onClick}
    />
  );
};

export const RowSimpleText =
  (
    key,
    weight = 'normal',
    blue,
    {
      disabled,
      size = '_M',
      onClick = () => Utils.trueReturn,
      copyable,
      customColor,
    } = {}
  ) =>
  item => {
    let text;

    if (isArray(key))
      key.forEach(k => (text = text != '' && text != null ? text : item[k]));
    if (typeof key === 'string') text = item[key];
    if (key == null) text = item;
    if (text == null) text = Constants.fallback;

    let TextComponent;
    switch (weight) {
      case 'light':
        TextComponent = RowLightText;
        break;
      case 'bold':
        TextComponent = RowBoldText;
        break;
      case 'normal':
      default:
        TextComponent = RowText;
    }

    const handleClick = onClick(item);

    if (copyable) {
      return (
        <CopyableText
          text={
            <TextComponent
              text={text}
              blue={blue}
              disabled={disabled}
              size={size}
              onClick={handleClick}
            />
          }
          copyText={text}
        />
      );
    }
    return (
      <TextComponent
        text={text}
        blue={blue}
        disabled={disabled}
        size={size}
        onClick={handleClick}
        customColor={customColor}
      />
    );
  };

export const RowText = ({
  text,
  blue,
  disabled,
  size,
  onClick,
  customColor,
}) => (
  <BaseText
    text={text}
    color={blue ? Colors.Primary._600 : customColor}
    disabled={disabled}
    size={size}
    onClick={onClick}
  />
);

export const RowLightText = ({ text, disabled, size, onClick }) => (
  <BaseText
    text={text}
    color={Colors.Neutral._400}
    disabled={disabled}
    size={size}
    onClick={onClick}
  />
);

export const RowBoldText = ({
  text,
  ellipsis,
  blue,
  disabled,
  size,
  onClick,
  customColor,
}) => {
  return (
    <BaseText
      text={text}
      weight={FontKeys.Weight.Semibold}
      ellipsis={ellipsis}
      disabled={disabled}
      blue={blue}
      size={size}
      onClick={onClick}
      customColor={customColor}
    />
  );
};

export const User =
  (
    nameKey,
    imageKey,
    {
      size = '_XS',
      blue = false,
      bold = false,
      disabled = false,
      border = true,
    } = {}
  ) =>
  item => {
    const { [nameKey]: username, [imageKey]: image } = item;

    const avatar = (
      <Avatar image={image} username={username} size={size} border={border} />
    );
    const text = RowSimpleText(nameKey, bold ? 'bold' : 'normal', blue)(item);

    return (
      <Flex
        align="center"
        gap="_XS"
        overflow="hidden"
        style={{ opacity: disabled ? 0.5 : 1 }}
      >
        {avatar}
        {text}
      </Flex>
    );
  };

export const RowAction =
  ({
    action = Utils.emptyFunction,
    icon,
    size = Size.Icon._S,
    theme = 'neutral',
    color = Colors.Neutral._500,
  }) =>
  item => {
    return (
      <Icon
        name={icon}
        size={size}
        theme={theme}
        color={color}
        onClick={action(item)}
      />
    );
  };

const textSizes = {
  _M: {
    variant: FontKeys.Variant.Paragraph,
    size: FontKeys.Size._S,
    weight: FontKeys.Weight.Regular,
  },
  _XS: {
    variant: FontKeys.Variant.Caption,
    size: FontKeys.Size._M,
    weight: FontKeys.Weight.Semibold,
  },
};

export const QuantityBox =
  ({ size = '_M', color, textColor, key }) =>
  item => {
    const { [key]: productQuantity } = item;
    return (
      <ProductQuantityBox size={size} color={color}>
        <Text text={productQuantity} color={textColor} {...textSizes[size]} />
      </ProductQuantityBox>
    );
  };

export const Selected = (toggleRowChecked, checkedRows) => (_, index) => {
  const setSelected =
    typeof toggleRowChecked === 'function'
      ? toggleRowChecked(_, index)
      : Utils.emptyFunction;

  return (
    <CheckBox
      selected={checkedRows?.includes(index)}
      setSelected={setSelected}
      size="_S"
    />
  );
};

export const TableCheckBox =
  (key, { size = '_M', color } = {}) =>
  item => {
    const { [key]: checked } = item;

    return (
      <CheckBox
        selected={checked}
        size={size}
        color={color}
        setSelected={Utils.emptyFunction}
      />
    );
  };

// Better name for this?
export const DotCompleted = key => item => {
  const { [key]: completed, [getTooltipKey(key)]: tooltip } = item;

  const color = completed ? Colors.Success._300 : Colors.Neutral._300;

  const { content } = tooltip ?? {};

  const component = <Dot color={color} />;

  return <Tooltip children={component} content={content} />;
};

export const DotStatus = key => item => {
  const { [key]: status, [getTooltipKey(key)]: tooltip } = item;
  const color = StatusColors[status] ?? Colors.Neutral._300;
  const { content } = tooltip ?? {};

  const component = <Dot color={color} />;

  return (
    <Tooltip children={component} content={parseStringModifiers(content)} />
  );
};

export const TableContractedBrandName =
  (brandKey, contractedKey, size = '_M') =>
  item => {
    const {
      [brandKey]: brand = Constants.fallback,
      [contractedKey]: isContracted,
    } = item;

    return (
      <ContractedBrandName
        brand={brand}
        isContracted={!!isContracted}
        size={size}
      />
    );
  };

export const SimpleStatus =
  (key, size = '_S', { disabled } = {}) =>
  item => {
    const { [key]: status } = item;

    return (
      <div style={{ opacity: disabled ? 0.5 : 1 }}>
        <Badge type={BadgeType.status} text={status} size={size} />
      </div>
    );
  };

export const RowStatusShares =
  (key, maxWidth = 120) =>
  item => {
    const { [key]: shares } = item;

    return <StatusShares shares={shares} maxWidth={maxWidth} />;
  };

export const HoverIcon =
  (name = Icons.ChevronRight) =>
  hoveredRow =>
  (_, index) => {
    const isHovered = hoveredRow === index;
    return (
      <div style={{ display: isHovered ? 'block' : 'none' }}>
        <Icon name={name} color={Colors.Neutral._200} />
      </div>
    );
  };

export const RowOptionsMenu = ({ options, optionClick }) => {
  const Component = item => (
    <FloatingMenu
      options={options}
      optionClick={optionClick(item)}
      optionDisplay={({ label, ...props }) => (
        <Button
          variant="Tertiary"
          size="_S"
          text={label}
          black
          // cursor pointer
          onClick={Utils.emptyFunction}
          // custom props
          {...props}
        />
      )}
    >
      <Icon
        name={Icons.MoreVertical}
        color={Colors.Neutral._500}
        size={Size.Icon._M}
      />
    </FloatingMenu>
  );
  return {
    display: Component,
    action: Utils.avoidRowExpand,
  };
};

//
//
// ROW ITEMS - END

// STYLES - START
//
//
export const SubtableCSS = styled.div`
  padding: ${Layout.Spacing._S};
  #tableContainer {
    height: unset;
  }
  #tableShadow {
    display: none;
  }
  ${Styles.flexColumn};
`;

export const TableStyleWrapper = styled.div`
  height: 100%;
  display: flex;
`;

export const indicatorStyles = `.table-header._0, .table-cell._0 {
  padding-left: 0;
}`;

export const Dot = styled.div`
  min-width: 12px;
  min-height: 12px;
  background-color: ${props => props.color};
  border-radius: ${Effects.Border_Radius.Circle};
`;
//
//
// STYLES - END
