import { useEffect, useState } from 'react';
import ConfirmModal from '../../../../../components/molecules/ConfirmModal/ConfirmModal';
import { Utils } from '../../../../../constants';
import { useModal } from '../../../../../providers/ModalProvider';
import { useSnackbar } from '../../../../../providers/SnackbarProvider';
import useDeepCompareEffect from '../../../../../utils/useDeepCompareEffect';
import { parseStringModifiers } from '../../../../../utils/utils';
import { useGetAPIKey } from '../api/getAPIKey';
import { useRefreshAPIKey } from '../api/refreshAPIKey';
import { DevPortalLayout } from '../components/DevPortalLayout';
import {
  devPortalGrid,
  devPortalHeaders,
  noResultsState
} from './variables';

const DevPortal = () => {
  const [data, setData] = useState([]);
  const { dismiss, showModal } = useModal();
  const { showSnackbarError, showSnackbarSuccess } = useSnackbar();
  // const { store } = useStore();

  // const brandId = store[PropKeys.brandId];

  const {
    data: apiKey,
    isLoading,
    isError,
    error,
    // doFetchTokens,
    refetchAPIKey,
  } = useGetAPIKey();

  const {
    data: refreshData,
    isLoading: refreshIsLoading,
    isError: refreshIsError,
    error: refreshError,
    doRefreshAPIKey,
  } = useRefreshAPIKey();

  useDeepCompareEffect(() => {
    if (apiKey == null) return;
    setData(apiKey);
  }, [apiKey]);

  // useEffect(() => {
  //   showModal(DevPortal2FA, {
  //     onSuccess: key => {
  //       doFetchTokens(key, brandId);
  //       dismiss();
  //     },
  //   });
  // }, []);

  useEffect(() => {
    if (refreshIsLoading || refreshData == null) return;
    if (refreshIsError) {
      showSnackbarError(refreshError);
      return;
    }
    refetchAPIKey();
    showSnackbarSuccess('API key successfully refreshed');
  }, [refreshIsLoading, refreshData, refreshIsError]);

  const showRefreshAPIKey = () => {
    const title = 'Refresh API key';
    const description =
      parseStringModifiers(`Are you sure you want to refresh your brand's API key? This will invalidate the current API key and your integrations might break until the new API key is used.
      
      **This action cannot be undone**`);
    const confirm = 'Refresh';
    const onConfirm = () => {
      doRefreshAPIKey();
      dismiss();
    };
    const onCancel = dismiss;
    showModal(ConfirmModal, {
      title,
      description,
      confirm,
      onConfirm,
      onCancel,
    });
  };

  return (
    <DevPortalLayout
      headers={devPortalHeaders}
      data={[data]}
      grid={devPortalGrid}
      rowAction={Utils.emptyFunction}
      isLoading={isLoading}
      isError={isError}
      error={error}
      tableStatusProps={{
        noData: data.length === 0,
        noDataDisplay: noResultsState.devPortal.noData,
        noSearchResultsDisplay: noResultsState.devPortal.noData,
        // no filter setting, so no need to handle noSearchResults
      }}
      refreshAPIKey={() => showRefreshAPIKey}
    />
  );
};
export default DevPortal;
