import usePostData from '../../../../../hooks/postHook';

export const useRefreshAPIKey = () => {
  const [{ data, isLoading = true, isError, error }, doRefreshAPIKey] =
    usePostData('profile/api_key/refresh');
  
    return {
    data,
    isLoading,
    isError,
    error,
    doRefreshAPIKey,
  };
};
