import useFetchData from '../../../../../hooks/fetchHook';
import { maskEntry } from '../../../../../utils/utils';
import { devPortalKeys } from '../controllers/variables';

export const useGetAPIKey = () => {
  const [{ data = {}, isLoading = true, isError, error }, _, refetchAPIKey] =
    useFetchData('profile/api_key');

  return {
    data: maskData(data, devPortalMask),
    isLoading,
    isError,
    error,
    refetchAPIKey,
  };
};

const maskData = (data, mask) => {
  maskEntry(data, mask);
  return data;
};

const devPortalMask = {
  bpaKey: devPortalKeys.apiKey,
  bpaKeyExpirationDate: devPortalKeys.apiKeyExpirationDate,
  has_expired: devPortalKeys.apiKeyHasExpired,
};
