import { uniqueId } from 'lodash';
import * as Yup from 'yup';
import {
  Icons,
  NoData,
  NoResults,
} from '../../../../../components/atoms/Icon/Icon.options';
import { Constants, Utils } from '../../../../../constants';
import { PermissionKeys } from '../../../../../providers/PermissionProvider/PermissionsProvider';
import { cleanGridStyle } from '../../../../../utils/styleUtils';
import { parseStringModifiers } from '../../../../../utils/utils';

const {
  VIEW_BRAND_USERS,
  VIEW_BRAND_BILLING,
  VIEW_BRAND_CONTRACTS,
  VIEW_BRAND_DETAILS,
} = PermissionKeys.Brand.BRAND_MANAGEMENT;

const { VIEW_DEVELOPER_PORTAL } = PermissionKeys.Brand.DEVELOPER_PORTAL;

export const optionViews = {
  brandLogo: 'brandLogo',
  billing: 'billing',
  users: 'users',
  contracts: 'contracts',
  devPortal: 'devPortal',
};

export const options = [
  {
    icon: Icons.ImagePlus,
    title: 'Logo',
    description: 'Change brand logo',
    view: optionViews.brandLogo,
    permission: VIEW_BRAND_DETAILS,
  },
  {
    icon: Icons.Files,
    title: 'Billing',
    description: 'Edit plan, payment method and invoicing',
    view: optionViews.billing,
    permission: VIEW_BRAND_BILLING,
  },
  {
    icon: Icons.UserPlus,
    title: 'Users',
    description: 'Add or remove users',
    view: optionViews.users,
    permission: VIEW_BRAND_USERS,
  },
  {
    icon: Icons.Inbox,
    title: 'Contracts',
    description: 'View and download contracts',
    view: optionViews.contracts,
    permission: VIEW_BRAND_CONTRACTS,
  },
  {
    icon: Icons.Cpu,
    title: 'Developer Portal',
    description: 'View developer credentials',
    view: optionViews.devPortal,
    permission: VIEW_DEVELOPER_PORTAL,
  },
];

export const infoDescription = {
  [optionViews.brandLogo]:
    'For best results, upload an image with a minimum size of 144x144 pixels with white background.',
};

export const primaryText = ({ startUpload }) => {
  return startUpload ? 'Save' : 'Edit';
};

export const uploadIcon = {
  [optionViews.brandLogo]: Icons.Image,
};

export const promptText = {
  [optionViews.brandLogo]: parseStringModifiers(
    `Drag and drop your images \n\nor`
  ),
};

export const browseFileButtonText = {
  [optionViews.brandLogo]: 'Browse file',
};

const errorText = {
  [Constants.FileUpload.extensionError]: {
    [optionViews.brandLogo]: parseStringModifiers(
      `We only accept JPG or PNG files.\nPlease try again with another file type.`
    ),
  },
  [Constants.FileUpload.uploadError]: {
    [optionViews.brandLogo]: parseStringModifiers(
      `There was an error uploading this file.\nPlease try again.`
    ),
  },
};

export const uploadStatusContent = ({
  fileState,
  resetFileUpload,
  view,
  preexistingFile,
  startUpload,
}) => {
  const { files, isUploading, completedUpload, uploadSuccess, errorType } =
    fileState;
  if (!startUpload) {
    return {
      preexistingFile,
    };
  }

  if (!files) {
    return {
      icon: uploadIcon[view],
      text: promptText[view],
      button: {
        onClick: Utils.emptyFunction,
        text: browseFileButtonText[view],
      },
    };
  }

  if (isUploading)
    return {
      title: 'Uploading...',
    };

  if (!completedUpload) {
    const numOfFiles = files.length;
    let text = `Your file: **${files[0].name}** is ready to import`;
    if (numOfFiles > 1)
      text = `Your **${numOfFiles}** files are ready to import`;
    return {
      icon: Icons.Upload,
      title: 'Upload ready',
      text: parseStringModifiers(text),
    };
  }

  if (!uploadSuccess)
    return {
      icon: Icons.Warning,
      title: 'Upload failed',
      text:
        errorText[errorType]?.[view] ?? Constants.FileUpload.unexpectedError,
      button: {
        onClick: resetFileUpload,
        text: 'Try again',
      },
    };

  if (uploadSuccess)
    return {
      icon: Icons.CheckCircle,
      title: 'Uploading success',
      text: 'Your file has been successfully imported',
    };
};

export const invoiceKeys = {
  leftPadding: 'leftPadding',
  alias: 'alias',
  date: 'date',
  amount: 'amount',
  download: 'download',
  invoiceName: 'invoiceName',
  link: 'link',
  monthlyCost: 'monthlyCost',
  costPerCatch: 'costPerCatch',
  yearlyCost: 'yearlyCost',
  freeUsersCount: 'freeUsersCount',
  planName: 'planName',
  plan: 'plan',
};

export const invoiceHeaders = {
  leftPadding: '',
  [invoiceKeys.alias]: 'Invoice ID',
  [invoiceKeys.date]: 'Date',
  [invoiceKeys.amount]: 'Amount',
  [invoiceKeys.download]: '',
};

export const invoiceGrid = {
  gridTemplateColumns: cleanGridStyle(`
  // space //
  24px 
  // invoice alias //
  minmax(140px , 1fr)
  // date //
  minmax(140px , 1fr)
  //  amount //
  minmax(46px , 1fr)
  // download icon //
  minmax(20px , 0.25fr)`),
  gridAutoRows: '56px 0px',
  rowHeight: '56px',
};

export const usersKeys = {
  leftPadding: 'leftPadding',
  actions: 'actions',
  active: 'active',
  admin: 'admin',
  brandId: 'brandId',
  expansionArrow: 'expansionArrow',
  email: 'email',
  firstName: 'first_name',
  id: 'id',
  image: 'image',
  lastName: 'last_name',
  name: 'username',
  user: 'user',
  userId: 'userId',
  service: {
    addedBy: {
      image: 'addedByImage',
      name: 'addedByName',
    },
    dateAdded: 'dateAdded',
    icon: 'icon',
    title: 'title',
    role: 'role',
  },
  services: 'services',
};

export const usersHeaders = {
  [usersKeys.expansionArrow]: '',
  [usersKeys.user]: 'Name',
  [usersKeys.email]: 'Email',
  [usersKeys.services]: 'Services',
  [usersKeys.actions]: '',
};

export const usersGrid = {
  gridTemplateColumns: cleanGridStyle(`
  // space //
  40px 
  // name //
  minmax(80px , 0.75fr)
  //  email //
  minmax(120px , 0.75fr)
  //  services //
  minmax(120px , 0.6fr)
  // actions //
  48px`),
  gridAutoRows: '56px 0px',
  rowHeight: '56px',
};

export const serviceTitles = {
  undefined: 'Parcels',
  Parcels: 'Parcels',
};

export const userExpansionHeaders = {
  [usersKeys.leftPadding]: '',
  [usersKeys.service.title]: 'Service',
  [usersKeys.service.dateAdded]: 'Date added',
  [usersKeys.service.addedBy.image]: 'Added by',
  [usersKeys.service.addedBy.name]: '',
  [usersKeys.service.role]: 'Role',
};

export const usersExpansionGrid = {
  gridTemplateColumns: cleanGridStyle(`
  // padding //
  16px
  // service icon & title //
  minmax(180px , 1fr)
  // date added //
  minmax(60px, 140px)
  //  image //
  48px
  //  name //
  minmax(60px , 1fr)
  // role //
  minmax(80px , 110px)`),
  gridAutoRows: '56px 0px',
  rowHeight: '56px',
};

export const userOptions = {
  list: 'list',
  edit: 'edit',
};

export const writeUserInitialValues = user => ({
  [usersKeys.firstName]: user?.[usersKeys.firstName] ?? '',
  [usersKeys.lastName]: user?.[usersKeys.lastName] ?? '',
  [usersKeys.email]: user?.[usersKeys.email] ?? '',
});

export const writeUserValidation = Yup.object({
  [usersKeys.firstName]: Yup.string()
    .min(2, 'At least 2 characters')
    .max(50, 'Max 50 characters')
    .required('Please enter a name'),
  [usersKeys.lastName]: Yup.string()
    .min(2, 'At least 2 characters')
    .max(50, 'Max 50 characters')
    .required('Please enter a last name'),
  [usersKeys.email]: Yup.string()
    .email('Not a valid email')
    .required('Please enter an email'),
});

export const writeUserFields = ({ isEditing }) => [
  {
    key: uniqueId(),
    name: usersKeys.firstName,
    placeholder: 'Name',
  },
  {
    key: uniqueId(),
    name: usersKeys.lastName,
    placeholder: 'Last name',
  },
  {
    key: uniqueId(),
    name: usersKeys.email,
    placeholder: 'Email',
    readOnly: isEditing,
  },
];

export const contractKeys = {
  leftPadding: 'leftPadding',
  id: 'id',
  url: 'url',
  name: 'name',
  date: 'date',
  download: 'download',
};

export const contractHeaders = {
  [contractKeys.leftPadding]: '',
  [contractKeys.name]: 'Name',
  [contractKeys.date]: 'Date',
  [contractKeys.download]: '',
};

export const contractsGrid = {
  gridTemplateColumns: cleanGridStyle(`
  // space //
  24px 
  // name //
  minmax(100px , 0.5fr)
  // date //
  minmax(120px , 0.8fr)
  // download icon //
  minmax(20px , 0.15fr)`),
  gridAutoRows: '56px 0px',
  rowHeight: '56px',
};

export const devPortalKeys = {
  leftPadding: 'leftPadding',
  apiKey: 'apiKey',
  apiKeyExpirationDate: 'apiKeyExpirationDate',
  apiKeyHasExpired: 'apiKeyHasExpired',
  refresh: 'refresh'
};

export const devPortalHeaders = {
  [devPortalKeys.leftPadding]: '',
  // [devPortalKeys.name]: 'Name',
  [devPortalKeys.apiKey]: 'API Key',
  [devPortalKeys.apiKeyExpirationDate]: 'Expiration Date',
  [devPortalKeys.apiKeyHasExpired]: 'Status',
  [devPortalKeys.refresh]: '',
};

export const devPortalGrid = {
  gridTemplateColumns: cleanGridStyle(`
  // space //
  16px 
  // key //
  minmax(200px , 1fr)
  // date //
  minmax(150px , 0.3fr)
  // has expired //
  minmax(70px , 0.15fr)
  // refresh icon //
  48px`),
  gridAutoRows: '56px 0px',
  rowHeight: '56px',
};

export const noResultsState = {
  plan: {
    description: 'No active plan',
    icon: NoData.Plan,
  },
  invoices: {
    noData: {
      icon: NoData.Invoices,
      description: 'There are no invoices for the moment',
    },
  },
  users: {
    noData: {
      icon: NoData.Users,
      title: 'No users',
      description: 'There are no active users',
    },
    noSearchResults: {
      icon: NoResults.Users,
      title: 'No users',
      description: 'There are no active users',
    },
  },
  contracts: {
    noData: {
      icon: NoData.Contracts,
      title: 'No contracts',
      description: 'There are no contracts for the moment',
    },
  },
  devPortal: {
    noData: {
      icon: NoData.Default,
      title: 'No API key',
      description: 'There is no API key for the moment',
    },
  },
};

const devPortalFields = {
  key: 'key',
};

export const devPortalInitialValues = {
  [devPortalFields.key]: '',
};

export const devPortalValidationSchema = Yup.object().shape({
  [devPortalFields.key]: Yup.string()
    .min(4, '6 chars minimum')
    .max(6, '6 chars maximum')
    .required(),
});
